import { HTTP } from './http-common';

export default {
  fetch(plan_id) {
    return HTTP.get(`qnt/plans/${plan_id}/benefits/`);
  },
  getElEmpleoVIP() {
    return HTTP.get(`miQnt/Benefits/elempleovip/`);
  },
  activeElEmpleoVIP(data) {
    return HTTP.post(`qnt/benefits/elempleovip/`, data);
  }

}