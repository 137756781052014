import { HTTP } from './http-common';

//export const BASE_URL = "https://localhost:44306/TDR"

export default {
  async generateOTP (data) {
    // data : {
    //   Id: string,
    //   celular: string,
    //   email: string
    // }
      return HTTP.post(`/miQnt/TDR/OTP/generar`, data)
  },

  async validateOTP (data) {
  //   data : {
  //     Id:"00aq3e",
  //     identificador: "7ab39285-fda0-4120-abf5-92842b7f5c7c",
  //     textoOTP: "6552"
  // }
    return HTTP.post(`/miQnt/TDR/OTP/validar`, data)
  },
  validateOtpGeneral(data) {
    return HTTP.post(`/miQnt/TDR/OTP/validar/general`, data)
  },

  saveIdentifier (id) {
    sessionStorage.setItem('otp_id', id)
  },

  getIdentifier () {
    const res = sessionStorage.getItem('otp_id')

    return res !== null ? { msg : res , exist: true} : { msg:'', exist: false }
  },

  removeIdentifier () {
    sessionStorage.removeItem('otp_id')
  }
}