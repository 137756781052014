import payment from '@/services/payment.service'
import document from '@/services/document.service'
import parameters from '@/services/parameters.service'

const state = {
  all: [],
  document: '',
  channels: [],
  instructiveUrlList: [],
  currentChannel: null,
  products: {}
};

const getters = {
  havePayments: (state) => {
    return state.all.length > 1;
  },
};

const actions = {

  getAllPayments({
    commit
  }) {
    payment.getPayments()
      .then(res => {
        commit('setPayments', res.data);
      })
      .catch((error) => {
        if(error.response.status == 401){
          commit("deleteToken");
          window.location = '/signin'
        }
        throw new Error(error);
      });
  },

  getChannels({
    commit
  }, entity) {
    parameters.getChannelsPaymentList(entity)
      .then(res => {
        commit('setChannels', res.data);
      });
  },

  async getProductsAndPaymentChannels({
    commit
  }) {
    const products = await payment.getProducts();
    const entities = products.data["entities"];
    commit('setProducts', products.data);
    if (entities.length) {
      const payment_url = await parameters.getPaymentUrlList(entities);
      commit('setInstructiveUrlList', payment_url.data);
    }
  },

  switchChannel({
    commit
  }, channel) {
    commit('setCurrentChannel', channel);
    if (!channel.url) {
      document.getIntructivePayment(channel.id)
        .then(res => {
          commit('setDocument', res.data);
        });
    }
  },

};

const mutations = {
  setPayments(state, payments) {
    state.all = payments
  },

  setChannels(state, channels) {
    state.channels = channels
  },

  setInstructiveUrlList(state, instructiveUrlList) {
    state.instructiveUrlList = instructiveUrlList
  },

  setDocument(state, document) {
    state.document = document
  },

  setCurrentChannel(state, channel) {
    state.currentChannel = channel
  },

  setProducts(state, products) {
    state.products = products
  },
};


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}