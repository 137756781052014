import alert from '@/services/alert.service'

const state = {
  all: [],
  currentAlertID: null,
  currentIndex: -1
}

const getters = {
  alertsToday: (state) => {
    let today = new Date().toLocaleDateString();
    return state.all.filter(alert =>
      new Date(alert.create_at).toLocaleDateString() == today);
  },

  alertsNotRead: (state) => {
    return state.all.filter(alert => !alert.read);
  },

  countAlertsNotRead: (state, getters) => {
    return getters.alertsNotRead.length;
  },

  currentAlert: (state) => {
    return state.currentIndex != -1 ? state.all[state.currentIndex] : {}
  },

  paymentAlerts: (state, getters, rootState, rootGetters) => {
    let today = moment();

    let alerts = [];
    rootGetters['fees/pendingFees']
      .filter(fee => moment() >= moment(fee.fecha, 'YYYY-MM-DD').endOf('day'))
      .filter(fee => fee.saldo >= Math.min(10000, 0.1 * fee.valor))
      .forEach(fee => {
        let fee_date = moment(fee.fecha, 'YYYY-MM-DD');
        let days_for_next_fee = fee_date.diff(today, 'days');
        if (days_for_next_fee < 0) {
          alert = {
            id: `danger-${fee.fecha}`,
            subject: `Pago siguiente el ${fee_date.format('DD-MM-YYYY')}`,
            type: 'danger',
            content: `Recuerde pagar su cuota antes del ${fee_date.format('DD-MM-YYYY')} para seguir disfrutando de los beneficios de su acuerdo`,
            create_at: new Date(fee.fecha)
          };
          alerts.push(alert);
        } else if (days_for_next_fee < 2) {
          alert = {
            id: `warning-${fee.fecha}`,
            subject: "Pago pendiente",
            type: 'warning',
            content: `Recuerde pagar su cuota antes del ${fee_date.format('DD-MM-YYYY')}`,
            create_at: new Date(fee.fecha)
          };
          alerts.push(alert);
        }

      });

    return alerts
  },
}

const actions = {
  getAll({
    commit
  }) {
    alert.getAlerts()
      .then(alerts => commit("setAlerts", alerts.data));
  },

  switchAlert({
    commit,
    getters
  }, payload) {

    commit("switchAlert", payload);

    if (!getters.currentAlert.read) {
      alert.updateIsRead(payload)
        .then(() => commit("markAsRead"));
    }

  }
}

const mutations = {

  setAlerts(state, alerts) {
    state.all = alerts;
  },

  switchAlert(state, id) {
    setCurrentAlert(state, id)
  },

  markAsRead(state) {
    if (!state.all[state.currentIndex].read) {
      state.all[state.currentIndex].read = true
    }
  }
}


function setCurrentAlert(state, id) {
  state.currentAlertID = id;
  state.currentIndex = state.all.findIndex(alert => alert.id == state.currentAlertID);
}


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}