/**
 * Module to management Client's credit score
 */
import creditScore from '@/services/creditScore.service';

const state = {
  all : [],
  goal: "",
  current: "",
  tickets:{},
  details:{},
  BALANCE_KEYS : {
    "active_loans_balance":"Activos ", 
    "default_loans_balance": "En mora"},
  NUM_ENTITY_KEYS : {
    "num_nodefault_ent":"Al día",
    "num_default_ent_qnt": "En mora (QNT)", 
    "num_default_ent_noqnt":"En mora (FUERA QNT)",
  },
  NUM_PRODUCT_KEYS : {
    "num_nodefault_product": "Al día",
    "num_default_product": "En mora"
    }
};

const getters = {

  balances:(state) => {
    let details = state.details;
    let keys = Object.keys(state.BALANCE_KEYS);
    return keys.map(function(key){
      return details[key];
    });
  },
  numBalanceEntities:(state) => {
    let details = state.details;
    let keys = Object.keys(state.NUM_ENTITY_KEYS);
    return keys.map(function(key){
      return details[key];
    });
  },
  numBalanceProduct:(state) => {
    let details = state.details;
    let keys = Object.keys(state.NUM_PRODUCT_KEYS);
    return keys.map(function(key){
      return details[key];
    });
  }
};

const actions = {
  getAll({commit}){
    creditScore.getCreditScore()
    .then(score => {
      commit("setHistory", score.data.history);
      commit("setCurrentScore", score.data.current_score);
      commit("setGoal", score.data.goal);
      commit("setTickets", score.data.tickets);
    });
  },
  getDetails({commit}){
    creditScore.getCreditScoreDetail()
    .then(detail => {
      commit("setDetails", detail.data);
    });
  }
};

const mutations = {
  setHistory(state, data) {
    state.all = data;
  },
  setCurrentScore(state, data) {
    state.current = data;
  },
  setGoal(state, data) {
    state.goal = data;
  },
  setTickets(state, data) {
    state.tickets = data;
  },
  setDetails(state, data) {
    if(data){
      state.details = data;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}