import 'font-awesome/css/font-awesome.min.css'
import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import { Ripple } from 'vuetify/lib/directives'
import 'vuetify/dist/vuetify.min.css'

const opts = {}

Vue.use(Vuetify, {
  directives: {
    Ripple
  }
})

const vuetify = new Vuetify({

  icons: {
    iconfont: 'mdi', // default - only for display purposes
  },
  theme:{
      themes: {
          light: {
              primary: "#87BE28",
              secondary: "#00a0e1", 
              accent: "#0072ae",
              error: "#d50000",
            },
      },
  }
})

export default vuetify;