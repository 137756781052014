import {
  HTTP
} from './http-common';

export default {
  getPostFinanceEducation(id) {
    let data = [{
        "id": 1,
        relative_path: "blog/la-rebancarizacion-un-concepto-innovador-de-qnt-conozcalo/",
        title: "La Rebancarización: un concepto innovador de Qnt ¡Conózcalo!",
        img_src: "http://qnt.com.co/wp-content/uploads/2019/08/reloj-dinero.jpg",
      },
      {
        "id": 2,
        relative_path: "blog/5-cosas-que-debe-tener-en-cuenta-para-no-deteriorar-su-score-de-credito/",
        title: "5 cosas que debe tener en cuenta para no deteriorar su score de crédito",
        img_src: "http://qnt.com.co/wp-content/uploads/2019/08/balance-score.jpg",
      },
      {
        "id": 3,
        relative_path: "blog/puntaje-crediticio-historial/",
        title: "Historial crediticio y Puntaje crediticio: le contamos por qué son importantes",
        img_src: "http://qnt.com.co/wp-content/uploads/2019/08/madre-hija-ahorrando.jpg",
      },
      {
        "id": 4,
        relative_path: "blog/4-razones-por-las-que-ahorrar-es-la-mejor-decision/",
        title: "4 razones por las que ahorrar es la mejor decisión",
        img_src: "http://qnt.com.co/wp-content/uploads/2019/08/hombre-thumbsup.jpg",
      },
      {
        "id": 5,
        relative_path: "blog/el-presupuesto-elementos-e-importancia-en-nuestras-finanzas-personales/",
        title: "El presupuesto: elementos e importancia en nuestras finanzas personales",
        img_src: "http://qnt.com.co/wp-content/uploads/2019/08/calculadora-balance.jpg",
      }
    ]
    return data;
  },
}