import axios from 'axios';
import {
  TokenService
} from '@/services/storage.service'

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'

export let HTTP = axios.create({
  headers: {
    Authorization: 'Bearer ' + TokenService.getToken()
  }
});
axios.interceptors.response.use(
  response => response,
  async error => {
      if (error.response && error.response.status === 401) {
          // Manejar el error 401 aquí
          // Por ejemplo, redirigir al usuario a la página de inicio de sesión
          console.error("Unauthorized access - redirecting to login");
          TokenService.removeToken(); // Elimina el token almacenado
          window.location = "/signin"; // Redirige a la página de inicio de sesión
      }
      return Promise.reject(error);
  }
);
