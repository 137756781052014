import fee from '@/services/fee.service'

const state = {
    all: [],
    next_fee: {},
}

const getters = {
    pendingFees: (state) => {

        return state.all.filter(item =>
            item.estado === 'Pendiente pago' || item.estado === 'Abonada' || item.estado === 'Abonada Anterior Acuerdo');
    },
    nextFee: (state, getters) => {
        /**Return next pending fee**/
        if (getters.pendingFees.length == 1) {
            return getters.pendingFees[0];
        } else if (getters.pendingFees.length > 1) {
            const firstFee = getters.pendingFees[0];
            const minValue = Math.min(10000, 0.1 * Number(firstFee.valor));
            if (Number(firstFee.saldo) > minValue) {
                return getters.pendingFees[0];
            }
            let secondFee = { ...getters.pendingFees[1] };
            secondFee["saldo"] = Number(secondFee["saldo"]) + Number(firstFee.saldo);
            return secondFee;
        }

        return {};
    },
    nextFeeList: (state) => {
        /**Return next two fees from current date**/
        let feeList = [];
        for (let i = 0; i < state.all.length && feeList.length < 2; i++) {
            const item = state.all[i];
            if (moment() <= moment(item.fecha, 'YYYY-MM-DD')) {
                feeList.push(item);
            }
        }
        return feeList;
    },
    hasPendingFees: (state, getters) => {
        /**Compare current date with pending fee list and return true if next fee is pending to 
         * payment where greater to min(10000, 0.1*fee) **/
        if (getters.pendingFees.length == 0) {
            return false;
        }
        const pendingList = getters.pendingFees
            .filter(fee => moment() >= moment(fee.fecha, 'YYYY-MM-DD').endOf('day'))
            .filter(fee => Number(fee.saldo) >= Math.min(10000, 0.1 * Number(fee.valor)));

        return pendingList.length > 0;
    },
    debtFeeAmount: (state, getters) => {
        /**Compare current date with date's next fee and return true if next fee is pending to payment**/
        let feeAmount = 0;
        for (let i = 0; i < getters.pendingFees.length; i++) {
            const item = getters.pendingFees[i];
            if (moment() > moment(item.fecha, 'YYYY-MM-DD').endOf('day')) {
                feeAmount += Number(item.saldo);
            }
        }
        return feeAmount;
    },
}

const actions = {
    getAllFees({
        commit
    }) {
        fee.getFees().then(
            payments => {
                commit('setFees', payments.data)
            });
    }
}

const mutations = {
    setFees(state, fees) {
        state.all = fees;
    }
}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}