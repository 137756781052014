import { HTTP } from './http-common';

export default {
  getStateList() {
    return HTTP.get('/miQnt/Parameters/states/');
  },
  getCitiesByStateList(state_id) {
    return HTTP.get(`/parameters/states/${state_id}/`);
  },
  getCitiesByNameStateList(state_name) {
    return HTTP.get(`/miQnt/Parameters/states/${state_name}/cities/`);
  },
  getAllCities() {
    return HTTP.get(`/parameters/cities/`);
  },
  getDebtReasonsList(state_id) {
    return HTTP.get(`/miQnt/parameters/domains/debtReasons/`);
  },
  getChannelsPaymentList(entity) {
    return HTTP.get(`/parameters/financeEntity/${entity}/`);
  },
  getPaymentUrlList(entity) {
    return HTTP.get(`/miQnt/Parameters/payment_urls/${entity}/`);
  },
  getCreditCardReason(id) {
    return HTTP.get(`/miQnt/Parameters/domains/credit_card_reason/${id}/`);
  },
  getAllCountries() {
    return HTTP.get(`/miQnt/parameters/countries/`);
  },
  geCIIUList() {
    return HTTP.get(`/miQnt/parameters/domains/CIIU/`);
  },
}