import { HTTP } from './http-common';

export default {
  get(plan_id) {
    return HTTP.get(`/miQnt/UserPlans/${plan_id}`)
      .catch((error) => {
        throw new Error(error);
      });
  },

  fetch() {
    return HTTP.get(`/miQnt/UserPlans/`)
      .catch((error) => {
        throw new Error(error);
      });
  }
}