import { HTTP } from './http-common'
const BASE_URL = "https://localhost:7118"

export default {
  getProducts() {
    return HTTP.get('/miQnt/Products/')
  },
  createDesvare(data) {
    return HTTP.post('/qnt/qnt_products/', data)
  },

  getCreditCards() {
    return HTTP.get('/miQnt/Products/credit_card/')
  },
  createCreditCard(data) {
    return HTTP.post(`/qnt/qnt_credit_card/`, data)
  },
  updateCreditCard(data) {
    return HTTP.post(`/qnt/qnt_credit_card/`, data)
  },
  updateActivationStateCreditCard(data) {
    return HTTP.post("/qnt/qnt_credit_card/activation_state", data)
  },
  /**/
  requestCCAcceptingDocuments(data) {
    return HTTP.post(`/miQnt/qnt-tdc/`, data)
  },
  requestUpdateisDigitalValue(id, data){
    return HTTP.patch(`/miQnt/qnt-tdc/${id}/change-isDigital-value/`, data)
  },
  requestCCContacts(id, data) {
    return HTTP.patch(`/miQnt/qnt-tdc/${id}/contact/`, data)
  },
  requestCCIdentificationNumber(id, data) {
    return HTTP.patch(`/miQnt/qnt-tdc/${id}/document_identification/`, data)
  },
  requestCCPersonalData(id, data) {
    return HTTP.patch(`/miQnt/qnt-tdc/${id}/personal_data/`, data)
  },
  requestCCEconomicActivity(id, data) {
    return HTTP.patch(`/miQnt/qnt-tdc/${id}/economic_activity/`, data)
  },
  requestCCJobInformation(id, data) {
    return HTTP.patch(`/miQnt/qnt-tdc/${id}/job_information/`, data)
  },
  requestCCFinanceInformation(id, data) {
    return HTTP.patch(`/miQnt/qnt-tdc/${id}/finance_information/`, data)
  },
  requestCCReferences(id, data) {
    return HTTP.patch(`/miQnt/qnt-tdc/${id}/references/`, data)
  },
  requestCCSupport(id, data) {
      return HTTP.patch(`/miQnt/qnt_cc_document/support/${id}/`, data)
  },
  requestCCOTP(id, data) {
    return HTTP.patch(`/miQnt/qnt-tdc/${id}/otp/`, data)
  },
  requestCCGenerateOTP(id, data) {
    return HTTP.patch(`/miQnt/qnt-tdc/${id}/generate_otp/`, data)
  },
  requestAuthorizeActivation(id, data) {
    return HTTP.patch(`/miQnt/qnt-tdc/${id}/authorize_activation`, data)
  },
  requestCreditCard(id, data) {
    return HTTP.post(`/qnt/qnt-tdc/`, data);
  },
  /**/
  getRequestCreditCard(id) {
    return HTTP.get(`/miQnt/qnt-tdc/${id}/`);
  }
}