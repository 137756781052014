import { HTTP } from './http-common';

export default {
  getFees() {
    return HTTP.get('/miQnt/Fees/')
      .catch((error) => {
        throw new Error(error);
      });
  },

  getListByPlan(id) {
    return HTTP.get(`/miQnt/Fees/${id}`)
      .catch((error) => {
        throw new Error(error);
      });
  }
};