import {
  HTTP
} from './http-common';

export default {
  getIntructivePayment(id) {
    return HTTP.get(`/qnt/documents/${id}/`);
  },


  getHtmlDocument(id) {
    return HTTP.get(`/qnt/v2/legal/documents/${id}/`);
  },

  download(id, filename) {
    return HTTP.post(`/qnt/documents/${id}/`, {
        responseType: 'blob',
        timeout: 30000,
      })
      .then(response => {
        this._downloadFile(response.data, filename)
      });
  },

  _downloadFile(data, filename, type = "pdf") {
    let blob = new Blob([data], {
      type: type
    })
    let url = window.URL.createObjectURL(blob);
    let a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
  },

  downloadDash(id, params, filename = "datos.csv") {
    return HTTP.get(`/qnt/dash/${id}/`, {
        responseType: 'blob',
        timeout: 600000,
        params: params
      })
      .then(response => {
        this._downloadFile(response.data, filename, "csv")
      });
  },


  getDocumentList() {
    return HTTP.get(`/miQnt/documents/`);
  },

  getPazSalvo() {
    return HTTP.get(`/miQnt/documents/`);
  },



  getPdfLegalDocument(id, filename) {
    return HTTP.get(`/miQnt/documents/${id}/`, {
        responseType: 'blob',
        timeout: 30000,
      })
      .then(response => {
        this._downloadFile(response.data, filename)
      });
  },

  //


}