<template>
  <footer class="footer footer pa--lg-8 pa-lg-5 pa-3">
    <!-- footer -->
    <section
      class="
          row
          align-start"
    >
      <div class="col col-lg-5">
        <div class="column-inner pl-5">
          <div class="row icon-qnt-grey">
            <img
              src="../../assets/img/qnt-logo-gray.png"
              alt="logo gris qnt"
              width="200px"
              color="white"
            />
          </div>
          <div class="row social d-flex justify-content-center">
            <div class="col col-sm-4">
              <a href="https://www.linkedin.com/company/qntcolombia/" target="_blank">
                <img
                src="../../assets/img/linkedin-logo.png"
                alt=""
                width="45px"
                color="white"
              />
              </a>
            </div>
            <div class="col col-sm-4">
              <a href="https://www.facebook.com/QNTcolombia/" target="_blank">
                <img
                src="../../assets/img/facebook-logo.png"
                alt=""
                width="45px"
                color="white"
              />
              </a>
            </div>
            <div class="col col-sm-4">
              <a href="https://www.instagram.com/qnt_colombia/" target="_blank">
                <img
                src="../../assets/img/instagram-logo.png"
                alt=""
                width="45px"
                color="white"
              />
              </a>
            </div>
          </div>
          <div class="row info-contacto">
            <div class="col-12 p-0">
              <span class="text-white">WhatsApp <span>321 239 8964</span></span>
            </div>
            <div class="col-12 p-0">
              <span class="text-white"
                >Telefono <span>(601) 918 31 31</span></span
              >
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-7 d-none d-lg-flex qnt-cert py-0">
        <section class="row row-fluid align-center w-100">
          <div class="col col-sm-4">
            <img
              class="alignnone size-full wp-image-309635 footer-logo"
              src="../../assets/img/fintech-logo.png"
              alt="logo fintech"
            />
          </div>
          <div class="col col-sm-4">
            <img
              class="alignnone size-full wp-image-51055 footer-logo"
              src="../../assets/img/CCE-logo-blanco.png"
              alt=""
            />
          </div>
          <div class="col col-sm-4">
            <img
              class="alignnone size-full wp-image-309635 footer-logo"
              src="../../assets/img/great-place-to-work-logo.png"
              alt=""
            />
          </div>
        </section>
      </div>
      <div class="row text-center">
        <div class="col col-sm-12">
          <p class="mb-0">Politica de privacidad</p>
          <p>&copy; QNT SAS Colombia, 2024</p>
        </div>
      </div>
    </section>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style lang="scss" scoped>
.footer {
  background-color: #01273c;
  color: #ccc;
  .qnt-cert img {
    width: 200px;
  }
  &-logo img {
    height: 60px;
  }
}
@media (min-width: 992px) { /* Pantalla lg o mayor */
  .social {
    width: 50%;
  }
}
</style>
