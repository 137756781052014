import plan from '@/services/plan.service'
import benefit from '@/services/benefit.service'
import fee from '@/services/fee.service'

const state = {
  plan: {},
  all: [],
  benefits: [],
  fees: [],
};

const getters = {
  /** If any plan have past due days >=1 then the client delinquent accounts 
   */
  haveDelinquentAccounts: (state) => {
    return state.all.filter(plan => plan.past_due_days > 0 ).length > 0
  }
};

const actions = {

  get({
    commit
  }, id) {
    plan.get(id)
      .then(res => {
        commit('setPlan', res.data);
      });

    benefit.fetch(id)
      .then(res => {
        commit('setBenefits', res.data);
      });

    fee.getListByPlan(id)
      .then(res => {
        commit('setFees', res.data);
      });
  },

  list({
    commit
  }) {
    plan.fetch()
      .then(res => {
        commit('setPlans', res.data);
      });
  }
};

const mutations = {
  setPlan(state, plan) {
    state.plan = plan;
  },
  setBenefits(state, data) {
    state.benefits = data;
  },
  setPlans(state, data) {
    state.all = data;
  },
  setFees(state, data) {
    state.fees = data;
  },
};


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}