import {
  HTTP
} from './http-common';
import axios from 'axios';
axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'
export default {
  changePassword(data) {
    return HTTP.post('/auth/password/change/', data)
  },
  signup(data) {
    return axios.post('/miQnt/Auth/registration/', data)
  },
  resetPassword(data) {
    return axios.post('/auth/password/reset/', data);
  },
  async login(data) {
    return axios.post('/miQnt/auth/login/', data);
  },
  async sa(data) {
    return axios.post('/auth/sa/', data);
  },
  logout() {
    return axios.post('/auth/logout/')
      .catch(error => {
        if (process.env.NODE_ENV == "development") {
          return Promise.reject(error);
        }
      });
  },
  /**
   * Retrive masked and mobile number of user
   * @params {identification_number} params
   * @param {*} data
   * @returns Promise
   */
  async getMaskedPreferredLocation(params, data) {
    const res = await axios.patch(
      `/miQnt/auth/password/reset/ubications/CC/${params.identification_number}/`, data);
    return res;
  },
  /**
   * Send otp to location of user
   * @params {identification_number} params
     @param {*} data
   * @returns Promise
   * 
   */
  async sendOTP(params, data) {
    return axios.post(
      `/miQnt/auth/password/reset/ubications/CC/${params.identification_number}/`, data);
  },

  /**
   * Resert password with Otp
     @param {*} data
   * @returns Promise
   * 
   */
  async verifyOtpToken(data) {
    return axios.post('/miQnt/auth/password/reset/confirm_token/', data);
  },
  async resetPasswordOTP(data) {
    return axios.post('/miQnt/auth/password/reset/set_new/', data);
  },
  async verifyDocument(document) {
    return axios.get('/miQnt/auth/verify-document-number/'+ document);
  },
  async validateExpeditionDate(data) {
    return axios.post('/miQnt/auth/validate-expedition-date', data);
  },
  async createContacLocations(params, data) {
    return axios.post(`/miQnt/auth/contact/new_location/${params.identification_number}`, data);
  }
}