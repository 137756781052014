import financeEducation from '@/services/post.service'

const state = {
  all : [],
  publicPost: [],
  privatePost: [],
};

const getters = {
  allPost: (state) => {
    return state.publicPost.concat(state.privatePost);
  },
};

const actions = {
  getPublicPost({commit}){
    let data = financeEducation.getPostFinanceEducation();
    commit("setPublicPost", data);
  },
};

const mutations = {
  setPublicPost(state, data) {
    state.publicPost = data;
  },
};


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}