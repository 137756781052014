const state = {
  isOpenModalOtp: false
}

const mutations = {
  setIsOpenModalOtp(state, data) {
    state.isOpenModalOtp = data
  }
}
const getters = {

}
const actions = {
  
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}