import parameters from '@/services/parameters.service'

const state = {
  cities: [],
  allCountries:[],
  allCities: [],
  creditCardReason: {},
  debtReasons: [],
  states: [],
  termCredit: [],
  binaryList: [{label:"SI", value:true}, {label:"NO", value: false}],
  economicActivityList: [
        { text: 'Empleado', icon: 'mdi-briefcase', value: "Asalariado" },
        { text: 'Independiente', icon: 'mdi-account-outline', value: "Independiente" },
        { text: 'Pensionado', icon: 'mdi-glasses', value: "Pensionado" },
      ],
  sexList: ["Masculino", "Femenino", "No informa"],
  ciiuList: [],
};

const getters = {};

const actions = {

  getStates({commit}){
    parameters.getStateList()
    .then(res =>{
      commit("setStates", res.data);
    })
    .catch((error) => {
    });
  },

  getCities({commit}, state){
    if (state != undefined){
      parameters.getCitiesByStateList(state)
      .then(res =>{
        commit("setCities", res.data);
      })
      .catch((error) => {
      });
    }else{
      commit("setCities", []);
    }
  },

  getAllCities({commit},){
      parameters.getAllCities()
      .then(res =>{
        commit("setAllCities", res.data);
      })
      .catch((error) => {
        throw new Error(error)
      });
  },

  getAllCountries({commit},){
      parameters.getAllCountries()
      .then(res =>{
        commit("setAllCountries", res.data);
      })
      .catch((error) => {
        throw new Error(error)
      });
  },

  getDebtReasons({commit}){
    parameters.getDebtReasonsList()
    .then(res =>{
      commit("setDebtReasons", res.data);
    })
    .catch((error) => {
    });
  },

  async getCreditCardReason({commit}, id){
    const res = await parameters.getCreditCardReason(id);
    commit("setCreditCardReason", res.data);
    return res;
  },

  getTermsCredit({commit}){
    commit("setTermCredit", [1,2,3]);
  },

  geCIIUList({commit}){
    parameters.geCIIUList()
    .then(res =>{
      commit("setCIIUList", res.data);
    })
    .catch((error) => {
      throw new Error(error)
    });
  },

};

const mutations = {

  setStates(state, data) {
    state.states = data;
  },
  setCities(state, cities) {
    state.cities = cities;
  },
  setAllCities(state, cities) {
    state.allCities = cities;
  },
  setAllCountries(state, data) {
    state.allCountries = data;
  },
  setCreditCardReason(state, cities) {
    state.creditCardReason = cities;
  },

  setDebtReasons(state, data) {
    state.debtReasons = data;
  },

  setTermCredit(state, data) {
    state.termCredit = data;
  },

  setStates(state, states) {
    state.states = states;
  },
  setCIIUList(state, data) {
    state.ciiuList = data;
  },
};


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}