const TOKEN_KEY = 'acces_token';
const OTP_TOKEN_KEY = 'acces_otp_token'

const REFRESH_TOKEN_KEY = 'refresh_token'


import axios from 'axios';
axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'

const TokenService = {
    getToken(token) {
        localStorage.removeItem('modal');
        localStorage.removeItem('ch_ps')
        if ($cookies.isKey('acces_token')) {
            this.saveToken($cookies.get('acces_token'));
        } else if (token) {
            // Auto login
            $cookies.remove('acces_token');
            try {
                axios.post(`/auth/sa/`, {
                        token
                    })
                    .then(res => {
                        if (res.data.accessToken) {
                            $cookies.set(TOKEN_KEY, res.data.accessToken);
                            this.saveToken(res.data.accessToken)
                            axios.defaults.headers.common['Authorization'] = 'Token ' + res.data.accessToken;
                            axios.defaults.xsrfCookieName = 'csrftoken';
                            axios.defaults.xsrfHeaderName = 'X-CSRFToken';
                            if (Boolean(res.data.ch_ps)) {
                                localStorage.setItem('ch_ps', Boolean(res.data.ch_ps));
                            }
                            window.location = "/"
                        }
                    })
            } catch (e) {}
        }

        return $cookies.get(TOKEN_KEY);
    },

    saveToken(accessToken) {
        $cookies.set(TOKEN_KEY, accessToken);
    },

    saveOtpToken(accessToken) {
        $cookies.set(OTP_TOKEN_KEY, accessToken, Number(process.env.VUE_APP_SESSION_TIME));
    },

    getOtpToken() {
        return $cookies.get(OTP_TOKEN_KEY);
    },
    saveRecoveryData(data) {
        $cookies.set(
            'recovery_data',
            JSON.stringify(data),
            Number(process.env.VUE_APP_SESSION_TIME));
    },
    getRecoveryData() {
        return JSON.parse($cookies.get('recovery_data'));
    },
    removeToken() {
        localStorage.removeItem(TOKEN_KEY);
        $cookies.remove(TOKEN_KEY);
        $cookies.remove(OTP_TOKEN_KEY);
    },

    getRefreshToken() {
        return localStorage.getItem(REFRESH_TOKEN_KEY)
    },

    saveRefreshToken(refreshToken) {
        localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken)
    },

    removeRefreshToken() {
        localStorage.removeItem(REFRESH_TOKEN_KEY)
    }

}

export {
    TokenService
}