import dashboard from '@/services/dashboard.service'

const state = {
  contactLogsByDate: {},
  contactLogsByHour:{},
  contactLogTotal: 0,
  contactLogTotalUrl: 0,
  contactInfoTotal: 0,
  userRegistrationTotal: 0,
  userAgreementTotal: 0,
  selOfferTotal:0,
};

const getters = {

  pct_preoffer : (state) => {
    return state.contactLogTotalUrl?((state.contactInfoTotal*100)/state.contactLogTotalUrl).toFixed(2):0;
  },
  pct_registration : (state) => {
    return state.contactLogTotalUrl?((state.userRegistrationTotal*100)/state.contactLogTotalUrl).toFixed(2):0;
  },
  pct_agreement : (state) => {
    return state.contactLogTotalUrl?((state.userAgreementTotal*100)/state.contactLogTotalUrl).toFixed(2):0;
  },
  pct_seloffer : (state) => {
    return state.contactLogTotalUrl?((state.selOfferTotal*100)/state.contactLogTotalUrl).toFixed(2):0;
  }
};


const actions = {


  getGeneralPage({commit}, data){
    dashboard.getGeneralPage(data)
    .then(res =>{
      commit("setContactLogsByDate", res.data.contactLogByDate);
      commit("setContactLogsByHour", res.data.contactLogByHour);
      commit("setContactLogTotal", res.data.total_log);
      commit("setContactLogTotalUrl", res.data.total_url);
      commit("setContactInfoTotal", res.data.total_preoferta);
      commit("setUserRegistrationTotal", res.data.total_registration);
      commit("setUserAgreementTotal", res.data.total_agreement);
      commit("setOfferSelTotal", res.data.total_offer_sel);
    })
    .catch((error) => {
    });
  },

};

const mutations = {

  setContactLogsByDate(state, contactLogsByDate) {
    state.contactLogsByDate = contactLogsByDate;
  },

  setContactLogsByHour(state, contactLogsByHour) {
    state.contactLogsByHour = contactLogsByHour;
  },

  setContactLogTotal(state, contactLogTotal) {
    state.contactLogTotal = contactLogTotal;
  },

  setContactLogTotalUrl(state, contactLogTotalUrl) {
    state.contactLogTotalUrl = contactLogTotalUrl;
  },

  setContactInfoTotal(state, contactInfoTotal) {
    state.contactInfoTotal = contactInfoTotal;
  },

  setUserRegistrationTotal(state, userRegistrationTotal) {
    state.userRegistrationTotal = userRegistrationTotal;
  },

  setUserAgreementTotal(state, userAgreementTotal) {
    state.userAgreementTotal = userAgreementTotal;
  },
  setOfferSelTotal(state, selOfferTotal) {
    state.selOfferTotal = selOfferTotal;
  },

};


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}