import authService from '@/services/auth.service'
import userService from '@/services/user.service'
import otpService from '../../services/otp.service';
import axios from 'axios';
import {
  HTTP
} from '@/services/http-common';
import {
  TokenService
} from '@/services/storage.service'

const state = {
  token: '',
  otpToken: '',
  status: '',
  changePassword: Boolean(localStorage.getItem('ch_ps')),
  stepChangePassword: 1,
  /* step 1: DNI validation 
    step 2: Choose contact location
    step 3: Validation otp sended
    step 4: Set new password
    step 5: Create new Locations
  */
  welcomeModal: false,
  errors: {},
  generalInformation: {},
  financeInformation: {},
  defaultData: {},
  defaultPlanId: "",
  preferredLocation: {},

  // estados de tarjeta de rebancarizacion
  tarjetaDigitalAutenticated: false,
  errorValidationTC: false,
  numberTD: '',
  cvv: '',
  fechaExpiracion:''
};

const getters = {
  hasErrors: (state) => {
    return Object.keys(state.errors).length > 0;
  },
  firstError: (state, getters) => {
    let key = Object.keys(state.errors)[0]
    return getters.hasErrors ? state.errors[key][0] : '';
  },
  name: (state) => {
    return `${state.generalInformation.first_name} ${state.generalInformation.last_name}`;
  },
  shortName: (state) => {
    return state.generalInformation.first_name ? state.generalInformation.first_name.split(' ')[0] : '';
  },
  selectedCity: (state, getters, rootState) => {
    if (!state.generalInformation || !rootState.parameters.cities.length) {
      return {};
    }

    let address_city = {};
    rootState.parameters.cities.forEach(element => {
      if (element.id == state.generalInformation.address_city) {
        address_city = element;
        return true;
      }
    });

    return address_city;
  },
  selectedState: (state, getters, rootState) => {
    if (!state.generalInformation || !rootState.parameters.states.length) {
      return {};
    }
    let addres_state = {};
    rootState.parameters.states.forEach(element => {
      if (element.codigo_dane == state.generalInformation.address_state) {
        addres_state = element;
        return true;
      }
    });
    return addres_state;
  },
  selectedPlan: (state, getters, rootState) => {
    if (!rootState.plans.all.length) {
      return {};
    }
    // First time selecting a value
    else if (!state.defaultPlanId && rootState.plans.all.length) {
      return rootState.plans.all[0];
    } else {
      let currentPlan = {};
      rootState.plans.all.forEach(plan => {
        if (plan.id == state.defaultPlanId) {
          currentPlan = plan;
          return true;
        }
      });
      return currentPlan;
    }
  },
  anyPayment: (state) => {
    return (state.generalInformation) ? state.generalInformation.any_payment : false;
  }
};


const actions = {

  async login({
    commit
  }, data) {
    delete axios.defaults.headers.common['Authorization'];
    const res = await authService.login(data);
    $cookies.set('acces_token', res.data.key);
    $cookies.set('acces_token_dj', res.data.token);
    commit('setToken', res.data.key);
    commit('setStatus', res.status);
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.key;
    axios.defaults.xsrfCookieName = 'csrftoken';
    axios.defaults.xsrfHeaderName = 'X-CSRFToken';
  },
  logout({
    commit
  }) {
    authService.logout();
    commit('setStatus', '');
    commit("deleteToken");
    otpService.removeIdentifier()
    commit('setTD_Autenticaded', false)
    window.location = '/';
  },

  changePassword({
    commit
  }, data) {

    commit('sendRequest');
    authService.changePassword(data)
      .then(res => {
        $("#m_modal_4").modal('hide');
        commit('disabledChangePassword');
        localStorage.removeItem('ch_ps');
        $cookies.remove('ch_ps');
      })
      .catch((error) => {
        commit('setError', error.response.data);
      });
  },

  async resetPassword({
    commit
  }, data) {
    const res = await authService.resetPassword(data);
    commit('setStatus', res.data);
  },

  async signUp({
    commit
  }, data) {

    const res = await authService.signup(data);
    commit('setToken', res.data.key);
    HTTP['headers'] = {
      Authorization: 'Bearer ' + res.data.key
    };
    commit('openModal');
  },

  updateProfile({
    commit
  }, data) {
    userService.updateGeneralInformation(data.generalInformation)
      .then(res => {
        commit("setGeneralInformation", res.data);
      })
      .catch((error) => {});

    userService.updateFinanceInformation(data.financeInformation)
      .then(res => {
        commit("setFinanceInformation", res.data);
      })
      .catch((error) => {});
  },

  getUserData({
    commit
  }, data) {
    console.log("esta es la dataaa" + data);
    userService.getInformation(data)
      .then(res => {
        commit("setGeneralInformation", res.data.generalInformation);
        commit("setFinanceInformation", res.data.financeInformation);
        commit("setDefaultData", res.data.default_data);
      })
      .catch((error) => {
        if (error.response.status == 401) {
          commit("deleteToken");
          window.location = '/signin'
        }
        throw new Error(error);
      });
  },

  changePlan({
    commit
  }, data) {
    commit("setCurrentPlan", data.id);
    userService.updatePlanDefault(data)
      .then(res => {
        commit("setCurrentPlan", res.data.id);
      })
      .catch((error) => {});
  },

  updateMobileTC({
    commit
  }, data) {
    userService.updateMobileTC(data)
      .then(res => {
        commit("setMobile", res.data.celular);
      });
  },

  async getPreferredLocation({
    commit
  }, data) {
    const res = await userService.getPreferredLocation(data)
    commit("setPreferredLocation", res.data);
  },

  async getMaskedPreferredLocation({
    commit
  }, data) {
    const identification_number = data.identification_number;
    const res = await authService.getMaskedPreferredLocation({
      identification_number
    }, data);
    commit("setPreferredLocation", res.data);
    return res.data;
  },

  async sendOTP({
    commit,
    dispatch,
    state
  }, data) {
    const identification_number = state.preferredLocation.identification_number;
    const res = await authService.sendOTP({
      identification_number
    }, data);
    commit("setOtpToken", res.data.key);
    TokenService.saveRecoveryData(data);
    console.log(data);
    
    console.log(data.increaseStep);
    
    if(data.increaseStep == false){
      return res.data;
    }
    const step = state.stepChangePassword + 1;
    commit('setStepChangePsw', step);
    return res.data;
  },

  async retryOtp({
    commit
  }) {
    const data = TokenService.getRecoveryData();
    const identification_number = data.identification_number;
    const res = await authService.sendOTP({
      identification_number
    }, data);
    commit("setOtpToken", res.data.key);
    return res.data;
  },

  async verifyToken({
    commit
  }, data){
    const res = await authService.verifyOtpToken(data);
    commit("setOtpToken", res.data.key);
    const step = state.stepChangePassword + 1;
    commit('setStepChangePsw', step);
  },

  async resetPasswordOTP({
    commit
  }, data) {
    const res = await authService.resetPasswordOTP(data);
    commit("setToken", res.data.key);
    return res.data;
  },
  async verifyDocumentNumber({ 
    commit,
    state
  }, data) {
    try{
      const { identification_number } = data
      const res = await authService.verifyDocument(identification_number)
      const step = state.stepChangePassword + 1;
      commit('setStepChangePsw', step);
    }
    catch(error) {
      throw error;
    }
  },
  async validateExpeditionDate({ 
    commit,
    state
  }, data) {
    try{
      const res =  await authService.validateExpeditionDate(data);
      const step = state.stepChangePassword + 1;
      commit('setStepChangePsw', step);
    }
    catch(error) {
      throw error;
    }
  },
  async createContactLocation({ 
    commit,
    state
  },data) {
    const { identification_number } = data
    const res =  await authService.createContacLocations({
      identification_number
    }, data);
    commit('setStepChangePsw', 3);
  }

};

const mutations = {

  setStatus(state, data) {
    state.status = data;
  },
  sendRequest(state) {
    state.status = "loading";
  },

  disabledChangePassword(state) {
    state.changePassword = false;
  },
  setError(state, errors) {
    state.errors = errors;
  },
  setToken(state, token) {
    state.token = token;
    TokenService.saveToken(token);
  },
  setOtpToken(state, token) {
    state.otpToken = token;
    TokenService.saveOtpToken(token);
  },
  deleteToken(state) {
    state.token = null;
    delete axios.defaults.headers.common['Authorization'];
    TokenService.removeToken();
  },

  closeModal(state) {
    localStorage.removeItem('modal');
    state.welcomeModal = false;
  },

  openModal(state) {
    localStorage.setItem('modal', true);
    state.welcomeModal = true;
  },

  setGeneralInformation(state, generalInformation) {
    state.generalInformation = generalInformation;
  },

  setFinanceInformation(state, financeInformation) {
    state.financeInformation = financeInformation;
  },

  setFinanceInformation(state, data) {
    state.defaultData = data;
  },

  setMobile(state, data) {
    state.generalInformation.celular = data;
  },

  setCity(state, address_city) {
    state.generalInformation.address_city = address_city;
  },

  setState(state, address_state) {
    state.generalInformation.address_state = address_state;
  },

  setDefaultData(state, data) {
    state.defaultData = data == null ? {} : data;
    state.defaultPlanId = state.defaultData.id ? state.defaultData.id : "";
  },

  setCurrentPlan(state, data) {
    Object.assign(state.defaultData, {
      "plan_id": data
    });
    state.defaultPlanId = data;
  },

  setPreferredLocation(state, data) {
    state.preferredLocation = data;
  },

  // mutaciones tdr digital
  setTD_Autenticaded(state, data) {
    state.tarjetaDigitalAutenticated = data
  },
  setNumber_td(state, data) {
    state.numberTD = data
  },
  setCVV(state, data) {
    state.cvv = data
  },
  setFechaExpiracion(state, data) {
    state.fechaExpiracion = data
  },
  setErrorValidationTC(state, data) {
    state.errorValidationTC = data
  },
  setStepChangePsw(state, data) {
    state.stepChangePassword = data
  }
  
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}