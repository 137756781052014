import Vue from 'vue';
import Vuex from 'vuex';
import alerts from './modules/alerts.module';
import documents from './modules/documents.module';
import fees from "./modules/fees.module";
import payments from './modules/payments.module';
import products from './modules/products.module';
import plans from './modules/plans.module';
import users from './modules/users.module';
import parameters from './modules/parameters.module';
import dashboard from './modules/dashboard.module';
import financeEducation from './modules/financeEducation.module';
import creditScore from './modules/creditScore.module';
import ui from './modules/ui.module'
import tdr from './modules/tdr.module'
import epayco from './modules/epayco.module'


Vue.use(Vuex)

const state = {
  connected: false,
  message: '',
  error: ''
};

const getters = {};

const actions = {};

const mutations = {
  /*
   SOCKET_CONNECT(state) {
     state.connected = true
   },
   SOCKET_DISCONNECT(state) {
     state.connected = false
   },
   SOCKET_MESSAGE(state, message) {
     state.message = message
   },
   SOCKET_HELLO_WORLD(state, message) {
     state.message = message
   },
   SOCKET_ERROR(state, message) {
     state.error = message.error
   },*/
};

export default new Vuex.Store({

  state,
  mutations,
  actions,
  modules: {
    getters,
    alerts,
    creditScore,
    dashboard,
    documents,
    financeEducation,
    fees,
    parameters,
    payments,
    plans,
    products,
    users,
    ui,
    tdr,
    epayco

  },

});