import { HTTP } from './http-common';

export default {
  getInfoCreditCard(data) {
    return HTTP.post(`/miQnt/TDR/DetalleTarjeta`,data)
  },

  getCreditCardIdentification(external_id){
    return HTTP.get(`/miQnt/TDR/tarjeta/contrato_centroAlta`, {
      params: {
        externalId: external_id
      }
    })
  },
  getArreasBalanceRP(contrato) {
    return HTTP.get(`/miQnt/TDR/tarjeta/saldoMoraRP`, {
      params: {
        contrato
      }
    })
  }

}
