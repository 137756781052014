import { HTTP } from './http-common';

export default {
  getAlerts() {
    return HTTP.get('/miQnt/Alerts/')
      .catch((error) => {
        throw new Error(error);
      });
  },
  updateIsRead(id) {
    return HTTP.put(`/qnt/alerts/${id}/`, {id:id, read:"true"})
      .catch((error) => {
        throw new Error(error);
      });
  }
}
