import benefits from '@/services/benefit.service';
import products from '@/services/products.service'
import userService from '@/services/user.service'
import tdrService from '@/services/tdr.service';
import Vue from 'vue';

const state = {
  all: {},
  creditCards: [],
  requestCreditCard: {},
  stepRequestCreditCard: 0,
  loading: false,
  requestMsg: {},
  preferredLocation: {},
  elempleovip: {},
};

const getters = {

  desvareWasUsed: (state) => {
    return state.all.state == "UTILIZADO" || state.all.state == "PENDIENTE RETIRO";
  },
  /**
   * Filter credit cards that they are pending to be digital proccess is pending and it is not cancelled
   * @param {*} state 
   * @returns 
   */
  activeCreditCard: (state) => {
    const credit_card = state.creditCards.filter(item => !(item.has_digital_signature || item.state.startsWith("Cancelada")));
    return (credit_card.length ? credit_card[0] : null)
  },
  acceptedCreditCard: (state) => {
    const credit_card = state.creditCards.filter(item => item.state == "Aceptada");
    return (credit_card.length ? credit_card[0] : null)
  },
  acceptedOrDelivered:(state) => {
    const credit_card = state.creditCards.filter(item => item.state == "Aceptada" || item.state == "Entregada");
    return (credit_card.length ? credit_card[0] : null)
  },
  canceledWithArreasBalanceRP:(state) => {
    const credit_card = state.creditCards.filter(item => item.state == "Cancelada" && item.saldoMoraRP > 0)
    console.log(credit_card);
    return (credit_card.length ? credit_card[0] : null)
  },
  anyCreditCard: (state) => {
    return state.creditCards.length > 0
  },
  hasSpecialDebt(state, getters) {
    if (!getters.activeCreditCard) {
      return false
    }
    return ["1E", "2D"].indexOf(getters.activeCreditCard.delinquentReason) >= 0;
  },
  isDigital: (state) => {
    const credit_card = state.creditCards.filter(item => item.type_card == "Digital")
    return (credit_card.length ? credit_card[0] : null)
  },
  isPhysical: (state) => {
    const credit_card = state.creditCards.filter(item => item.type_card == "Fisica")
    return (credit_card.length ? credit_card[0] : null)
  }
};

const actions = {

  getQntProducts({
    commit, dispatch
  }) {
    products.getProducts()
      .then(res => {
        commit('setProducts', res.data);
      });

    products.getCreditCards()
      .then(res => {
        commit('setCreditCards', res.data);
        dispatch('fetchCreditCardIdentification')
      });

    benefits.getElEmpleoVIP()
      .then(res => {
        commit('setElempleoVIP', res.data);
      });
  },

  async createDesvare({
    commit
  }, data) {

    const res = await products.createDesvare(data);
    commit('updateDesvare', res.data);
    return res.data;
  },

  async activeElEmpleo({
    commit
  }, data) {
    data["state"] = "Aceptado";
    const res = await benefits.activeElEmpleoVIP(data);
    commit('activeElEmpleo', res.data);
  },

  async requestCreditCard({
    commit
  }, data) {
    data["state"] = "Aceptada";
    const res = await products.createCreditCard(data);
    commit('updateCreditCard', res.data);
  },

  async rejectCreditCard({
    commit
  }, data) {
    data["state"] = "Rechazada";
    const res = await products.updateCreditCard(data);
    commit('updateCreditCard', res.data);
  },

  async getProcessCreditCard({
    commit, getters, state, dispatch
  }) {
    try {
      if (!getters.activeCreditCard) {
        const rescc = await products.getCreditCards()
        commit('setCreditCards', rescc.data)
      }
      const res = await products.getRequestCreditCard(getters.activeCreditCard.external_id);
      res.data.step++
      commit('updateRequestCreditCard', res.data)
      commit('setStepRequestCreditCard', res.data.step)
      if (state.stepRequestCreditCard <= 2 && !state.preferredLocation.mobile) {
        await dispatch("getPreferredLocation")
      }
    } catch (error) {
      if (error.response.status == 404) {
        commit('updateRequestCreditCard', { step: 1 })
        commit('setStepRequestCreditCard', 1)
      } else {
        throw new Error(error)
      }
    }
  },

  async getPreferredLocation({
    commit
  }, data) {
    const res = await userService.getPreferredLocation(data)
    commit("setPreferredLocation", res.data);
  },

  async requestProcessCreditCard({
    commit
  }, data) {
    try {
      commit('setLoading', true)
      const id = data.external_id
      if (data.step == 1) {
        await products.requestCCAcceptingDocuments(data)
      } else if (data.step == 2) {
        await products.requestCCContacts(id, data)
      } else if (data.step == 3) {
        await products.requestCCIdentificationNumber(id, data)
      } else if (data.step == 4) {
        await products.requestCCPersonalData(id, data)
      } else if (data.step == 5) {
        await products.requestCCEconomicActivity(id, data)
      } else if (data.step == 6) {
        await products.requestCCJobInformation(id, data)
      } else if (data.step == 7) {
        await products.requestCCFinanceInformation(id, data)
      } else if (data.step == 8) {
        await products.requestCCReferences(id, data)
      } else if (data.step == 9) {
        let formData = new FormData();
        formData.append('file_document', data['file_document'])
        formData.append('file_support', data['file_support'])
        formData.append('addrees_sent_at', data['addrees_sent_at'])
        formData.append('accepted_letter', data['accepted_letter'])
        formData.append('accepted_cc', data['accepted_cc'])
        await products.requestCCSupport(id, formData)
      } else if (data.step == 10) {
        await products.requestCCOTP(id, data)
      } else if (data.step == 11 && data.is_digital) {
        await products.requestAuthorizeActivation(id, data)
      } 
      // else if (data.step == 11) {
      //   await products.requestCreditCard(id, data)
      // }
      if (data.step != 12) {
        commit('addStepRequestCreditCard')
      }
      if (data.step) {
        commit('updateRequestCreditCard', data)
      }
      commit('setLoading', false)
    } catch (error) {
      commit('setLoading', false)
      throw error
    }
  },

  async generateOTPCC({
    commit
  }, data) {
    try {
      commit('setLoading', true)
      const id = data.external_id
      const res = await products.requestCCGenerateOTP(id, data)
      commit('setRequestMsg', res.data)
      commit('setLoading', false)
    } catch (error) {
      commit('setLoading', false)
      throw error
    }
  },
  async fetchCreditCardIdentification({commit, state, dispatch}) {
    console.log(state.creditCards.length+ " longitud");
    if (state.creditCards.length === 0) return

    try {
      const firstCard = state.creditCards[0]
      const externalId = firstCard.external_id

      const res = await tdrService.getCreditCardIdentification(externalId)
      const cardObj = {
        contrato: res.data.contrato,
        centroAlta: res.data.centroDeAlta
      }

      commit('updateCreditCardData', {index: 0, data: cardObj})
      dispatch('fetchArrearsBalanceRP')
    } catch(error){
      console.log(error);
      commit('resetCreditCardData', 0)
    }
  },
  async fetchArrearsBalanceRP({commit, state}) {
    try{
      const firstCard = state.creditCards[0]
      const contrato = firstCard.contrato
      console.log(contrato);
      const res =  await tdrService.getArreasBalanceRP(contrato)

      commit('updateCreditCardData',{index: 0, data: { saldoMoraRP: res.data.saldoMoraRP }})
    } catch(error) {
      commit('updateCreditCardData',{index: 0, data: { saldoMoraRP: null }})
    }
  },
  async rejectActivation({ commit, state }, {autoActivation, external_id }) {
    try {
      console.log(external_id);
      const data = {
        external_id,
        autoActivation
      }
      console.log("entro module");
      
      await products.updateActivationStateCreditCard(data)
    }
    catch(error){
      throw error
    }
  }
}

const mutations = {
  setProducts(state, products) {
    state.all = products
  },
  setCreditCards(state, products) {
    state.creditCards = products
  },
  setElempleoVIP(state, products) {
    state.elempleovip = products
  },
  updateDesvare(state, data) {
    state.all['state'] = data['state'];
    state.all['payment_date'] = data['payment_date'];
    state.all['accepted_at'] = data['accepted_at'];
    state.all['term'] = data['term'];
  },
  updateCreditCard(state, data) {
    state.creditCards.forEach(item => {
      if (item.id = data.id) {
        item["state"] = data['state'];
      }
    })
  },
  updateRequestCreditCard(state, data) {
    Object.assign(state.requestCreditCard, data)
  },
  activeElEmpleo(state, data) {
    Object.assign(state.elempleovip, data)
  },
  setStepRequestCreditCard(state, data) {
    state.stepRequestCreditCard = data
  },
  setLoading(state, data) {
    state.loading = data
  },
  setRequestMsg(state, data) {
    state.requestMsg = data
  },
  addStepRequestCreditCard(state) {
    state.stepRequestCreditCard++
  },
  setPreferredLocation(state, data) {
    state.preferredLocation = data;
  },
  updateCreditCardData(state, { index, data }) {
    for (const key in data) {
      // Usar Vue.set para asegurarse de que los cambios son reactivos
      Vue.set(state.creditCards[index], key, data[key]);
    }
  },
  resetCreditCardData(state, index) {
    const cardObj = {
      contrato: null,
      centroAlta: null
    };
  
    for (const key in cardObj) {
      // Usar Vue.set para asegurarse de que los cambios son reactivos
      Vue.set(state.creditCards[index], key, cardObj[key]);
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}