import document from "@/services/document.service";

const state = {
  legalDocuments: [],
  pazysalvoDocuments: [],
};

const getters = {};

const actions = {
  async getLegalDocument({ commit }) {
    await fetchAndCommitDocuments(
      commit,
      "setlegalDocuments",
      [
        "Formato de vinculación",
        "Autorizaciones",
        "Programa de rebancarización",
      ],
      document.getDocumentList
    );
  },

  async getPazSalvo({ commit }) {
    await fetchAndCommitDocuments(
      commit,
      "setPazysalvo",
      ["Pazysalvo", "pazysalvo"],
      document.getPazSalvo
    );
  },

  async getHtmlDocument({ commit }, id) {
    const res = await document.getHtmlDocument(id);
    return res.data;
  },
};

async function fetchAndCommitDocuments(commit, mutation, types, fetchFunction) {
  try {
    const res = await fetchFunction();
    commit(
      mutation,
      res.data.filter((item) => types.includes(item["tipo_documento"]))
    );
    console.log(res.data);
  } catch (error) {
    console.error(error);
  }
}

const mutations = {
  setlegalDocuments(state, data) {
    state.legalDocuments = data;
    //console.log(state.legalDocuments);
  },
  setPazysalvo(state, data) {
    state.pazysalvoDocuments = data;
    //console.log(state.pazysalvoDocuments);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
