// src/store/modules/epayco.js
import epaycoService from "../../services/epayco.service";

const state = {
    epaycoData: null,
    epaycoError: false,
    epaycoErrorMessage: "",
    tdrPaid: false,
    pendingTransaction: false,
    payRejected: false
};

const getters = {
    epaycoData: (state) => state.epaycoData,
    epaycoError: (state) => state.epaycoError,
    epaycoErrorMessage: (state) => state.epaycoErrorMessage,
};

const actions = {
    async getEpaycoData({ commit }) {
        try {
            const res = await epaycoService.getEpaycoData();
            commit("setEpaycoData", res.data);
        } catch (error) {
            commit("setEpaycoError", true);
            commit("setEpaycoErrorMessage", error.message);
        }
    },
    async getPaymentConfirmation({commit}, data) {
        try{
            const res = await epaycoService.paymentConfirmation(data)
            if(res.data.status_code === 1){
                commit("setPaidTdr", true)
            } else if(res.data.status_code === 3) {
                commit("setPendingTransaction", true)
            } else {
                commit("setPayRejected", true)
            }

        } catch(error) {

        }

    },
    confirmTransaction({commit}) {
        commit("setPaidTdr", true)
    }
};

const mutations = {
    setEpaycoData(state, data) {
        state.epaycoData = data;
    },
    setEpaycoError(state, error) {
        state.epaycoError = error;
    },
    setEpaycoErrorMessage(state, message) {
        state.epaycoErrorMessage = message;
    },
    setPaidTdr(state, data){
        state.tdrPaid = data
    },
    setPendingTransaction(state, data){
        state.pendingTransaction = data
    },
    setPayRejected(state, data){
        state.payRejected = data
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
