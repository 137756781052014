import { HTTP } from './http-common';

export default {
  getPayments() {
    return HTTP.get('/miQnt/Payments/')
      .catch((error) => {
        throw new Error(error);
      });
  },

  getProducts() {
    return HTTP.get('/miQnt/Payments/products')
  },

}